<template>
  <div id="about">
    <header>
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li><router-link to="assistant">抖微助手</router-link></li>
            <li><router-link to="flyfishoutlets">飞鱼系统</router-link></li>
            <li class="cur">
              <router-link to="commerce">跨境电商</router-link>
            </li>
            <!-- <li><router-link to="contact">联系我们</router-link></li> -->
            <li><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <img src="@/assets/images/details-banner.jpg" alt="" />
    </header>
    <section class="commerce container">
      <div class="pd100">
        <img src="@/assets/images/commerce-1.png" alt="" />
        <div class="fr">
          <div class="title">
            「飞鱼」跨境电商系统<span>可以解决以下痛点︰</span>
          </div>
          <p>1、海关报关对接，支持“三单对碰”和“179接口”。</p>
          <p>2、综合商城+分销+社区团购+直播，丰富的分销营销体系。</p>
          <p>3、微信小程序商城+电脑端网站，同步数据。</p>
          <p>
            4、拥有12年商城系统开发经验与5年的跨境电商经营的团队，成熟可靠的技术支持和系统保障能力!
          </p>
        </div>
      </div>
      <section class="container pd60">
        <div class="item-title">
          <img src="@/assets/images/c-icon.jpg" alt="" />
          <span>飞鱼跨境电商解决方案的亮点</span>
        </div>
        <ul class="bg">
          <li><img src="@/assets/images/c-bg-1.jpg" alt="" /></li>
          <li><img src="@/assets/images/c-bg-2.jpg" alt="" /></li>
          <li><img src="@/assets/images/c-bg-3.jpg" alt="" /></li>
          <li><img src="@/assets/images/c-bg-4.jpg" alt="" /></li>
          <li><img src="@/assets/images/c-bg-5.jpg" alt="" /></li>
        </ul>
      </section>
      <section class="container pd60">
        <div class="item-title">
          <img src="@/assets/images/c-icon.jpg" alt="" />
          <span>飞鱼跨境电商网站业务流程</span>
        </div>
        <img src="@/assets/images/commerce-3.jpg" alt="" />
      </section>
      <section class="container pd60">
        <div class="item-title">
          <img src="@/assets/images/c-icon.jpg" alt="" />
          <span>飞鱼跨境电商系统业务架构</span>
        </div>
        <img class="img1" src="@/assets/images/commerce-4.jpg" alt="" />
      </section>
    </section>
    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4>联系我们</h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话（微信同名）：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-3</a>
      </div>
    </footer>
  </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/style.css";
export default {};
</script>
<style lang="less" scope>
.commerce {
  .pd100 {
    display: flex;
    justify-content: space-around;
    img {
      width: 550px;
      height: 550px;
    }
    .fr {
      width: 725px;
      height: 390px;
      margin-top: 160px;
      .title {
        font-size: 40px;
        color: #0099df;
        margin-bottom: 20px;
        span {
          margin-left: 10px;
          font-size: 24px;
          color: #333;
        }
      }
    }
  }
  .pd60 {
    .main {
      display: flex;
      justify-content: space-around;
      .fr {
        margin-top: 50px;
      }
    }
  }
  p {
    font-size: 22px;
    padding-left: 55px;
  }
  .red {
    color: #0099df;
  }
  .bg {
    display: flex;
    justify-content: space-around;
  }
  .commerce {
    width: 475px;
    height: 400px;
  }
  .img1 {
    width: 1200px;
    height: 900px;
  }
}
</style>
